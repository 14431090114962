import extras from '../images/netsurfplay/extrasandpackages.png';

export default {
	features: [
		{
			image: extras,
			title: 'Избор от Пакети',
			text:
				'Нашият спектър от пакети - Филмов, Спортен, Златен стандарт и от А до Я, ти позволява да се гмурнеш в морето от телевизионни предавания, филми и спортни събития, чрез премиум канали като HBO, Diema Xtra, MAX Sport. Също така може да грабнеш безплатния код за регистрация в HBO MAX, с който стоенето пред телевизора или телефона не е просто действие, а емоция.'
		},
		{
			image: extras,
			title: 'Гледай любимите си предавания онлайн на живо',
			text:
				'Net Surf комбинира удобство и иновативност, позволявайки да гледаш по всяко време нашата богата гама от канали на мобилния си телефон, таблет или лаптоп, навсякъде с теб, независимо къде се намираш.'
		},
		{
			image: extras,
			title: 'Контролирай профила си',
			text:
				'Чрез приложението на NetSurf ще спестиш време и усилия, като собственоръчно провериш информация за услугата си или пуснеш заявка за повреда. Същевременно, след като си генерираш код от Netsurf Play, с който да се  регистрираш във Assistant на android устройство, сам ще правиш диагностика на услугата си.'
		}
	]
};

