import '../styles/common.css';

import { BgWrapper } from '../components/shared/components';
import DownloadAppBanner from '../components/shared/download-app-banner';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../components/shared/section-titles';
import { TitleH1 } from './magazini';
import constants from '../constants/svoboda';
import { graphql } from 'gatsby';
import meta from '../constants/meta-descriptions';

export default ({ data }) => {
	const title = data.wordpressPage.acf.title;

	let features = [];
	data.wordpressPage.acf.features.split('\n').map((rString) => {
		let parts = rString.split('###');

		if (parts[0]) {
			features.push({
				image: constants.features[0].image,
				title: parts[0],
				text: parts[1]
			});
		}
	});

	const appStoreLink = 'https://apps.apple.com/bg/app/netsurf-play/id1200797767';
	const googlePlayLink =
		'https://play.google.com/store/apps/details?id=io.bits84.netsurfplay';

	return (
		<>
			<BgWrapper bg={data.bg.childImageSharp.fluid.src} position={'bottom'}>
				<Layout>
					<SEO
						title={meta.netsurfplay.title}
						description={meta.netsurfplay.metaDescription}
						image={data.heroImg.childImageSharp.fluid.src}
					/>

					<div style={{ paddingTop: '8.5rem' }}>
						<div className="d-none d-lg-block play-container">
							<Img
								imgStyle={{ height: 400, objectFit: 'fill' }}
								fluid={data.heroImg.childImageSharp.fluid}
							/>
						</div>
						<div
							className="d-lg-none play-container"
							style={{ paddingBottom: '5rem' }}
						>
							<Img
								objectFit="cover"
								fluid={data.heroImg.childImageSharp.fluid}
							/>
						</div>
						<div style={{ maxWidth: 1280, margin: 'auto' }}>
							<SectionTitle>
								<TitleH1>{title}</TitleH1>
								<hr className="center" />
							</SectionTitle>
						</div>

						<BgWrapper
							bg={data.videoBg.childImageSharp.fluid.src}
							color={'transparent'}
						>
							<div className="position-relative row d-flex justify-content-center w-100">
								<div className="video-wrap">
									<iframe
										width="700"
										height="360"
										src="https://www.youtube.com/embed/OLOchEsBeAg"
										frameBorder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen
										title="NetSurf - GPON"
										style={{ maxWidth: '100%' }}
									></iframe>
								</div>
							</div>
						</BgWrapper>
						<div className="row d-flex justify-content-center my-5">
							<div className="col-12 col-lg-11 col-xl-9 row d-flex justify-content-center">
								{features.map(({ image, title, text }, index) => (
									<div
										className="col-12 col-sm-6 col-lg-4 text-center px-md-5 my-3 my-sm-5"
										key={index}
									>
										<ScrollAnimation
											animateIn="fadeIn"
											animateOnce={true}
										>
											<div
												className="d-flex justify-content-center align-items-center"
												style={{ minHeight: '100px' }}
											>
												<img
													src={image}
													className="img-fluid"
													alt={title}
												/>
											</div>
											<div className="px-sm-4">
												<h3>{title}</h3>
												<p>{text}</p>
											</div>
										</ScrollAnimation>
									</div>
								))}
							</div>
						</div>

						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<div className="container d-flex justify-content-center align-items-center mb-5">
								<DownloadAppBanner
									className="text-center mt-5"
									googlePlayLink={googlePlayLink}
									appStoreLink={appStoreLink}
								/>
							</div>
						</ScrollAnimation>
					</div>
				</Layout>
			</BgWrapper>
		</>
	);
};

export const query = graphql`
	query svoboda {
		wordpressPage(slug: { eq: "svoboda" }) {
			acf {
				features
				title
			}
		}
		bg: file(relativePath: { eq: "netsurfplay/bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
		heroImg: file(relativePath: { eq: "svoboda/svoboda.webp" }) {
			childImageSharp {
				fluid(maxHeight: 400) {
					...GatsbyImageSharpFluid
				}
			}
		}
		videoBanner: file(relativePath: { eq: "netsurfplay/video-banner.webp" }) {
			childImageSharp {
				fluid(maxHeight: 700) {
					...GatsbyImageSharpFluid
				}
			}
		}
		videoBg: file(relativePath: { eq: "netsurfplay/video-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

